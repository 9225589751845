<template>
  <div class="main-content h100">
    <router-view />
  </div>
</template>

<script setup>
  import { onMounted } from "vue"
  import { useAnalytics } from "@/composables/useAnalytics"
  import { whenExtensionMounted } from "@/utils"

  const { analytics } = useAnalytics()

  onMounted(() => {
    whenExtensionMounted().then((hasExtension) => {
      if (hasExtension)
        analytics.setGlobalProperties({
          hasExtensionInstalled: hasExtension
        })
    })
  })
</script>

<style lang="scss">
  @import "./styles/vars";

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    .main-content {
      min-height: fit-content;
    }
  }
</style>
