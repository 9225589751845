import { createStore } from "vuex"
import * as auth from "./modules/auth"

export default createStore({
  state: {
    installationId: null
  },
  getters: {},
  mutations: {
    SET_INSTALLATION_ID(state, value) {
      state.installationId = value
    }
  },
  actions: {},
  modules: {
    auth
  }
})
