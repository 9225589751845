import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./styles/helper-classes.scss"
import "./styles/base.scss"
import "./styles/typography.scss"
import { AnalyticsPlugin } from "@/plugins/analytics"
import pkg from "../package.json"

createApp(App)
  .use(store)
  .use(router)
  .use(AnalyticsPlugin, {
    router,
    store,
    extraProperties: {
      appContext: "auth-microservice",
      appVersion: pkg.version
    }
  })
  .mount("#app")
