import { AnalyticsBrowser } from "@segment/analytics-next"
import { AnalyticsEvents } from "./events.js"
import { Utils } from "@/utils"

const analytics = AnalyticsBrowser.load({
  writeKey: process.env.VUE_APP_SEGMENT_WRITE_KEY
})

export const injectionKey = "$analytics"

export class AnalyticsService {
  requiresIdentity = true
  globalProperties = {
    env: process.env.VUE_APP_MODE, // dev or prod
    appContext: "", // drawer | popup | auth-microservice
    appVersion: "", // as seen in packageJson
    hasExtensionInstalled: undefined
  }

  reset() {
    analytics.reset()
    this.requiresIdentity = true
  }

  getGlobalProperties() {
    return Utils.removeEmptyProperties(this.globalProperties)
  }

  identifyUser(user) {
    const props = {
      name: user.name,
      email: user.email,
      credits: user.credits,
      isPremium: user.isPremium,
      referrerCode: user.referrerCode,
      linkedinProfileUrl: user.userProfileUrl,
      resumeTemplate: user.templateCV,
      signUpDate: this.formatDate(user.createdAt),
      ...Object.fromEntries(
        [
          ["credit1UsedAt", user.creditsTimestamps?.credit1],
          ["credit2UsedAt", user.creditsTimestamps?.credit2],
          ["credit3UsedAt", user.creditsTimestamps?.credit3],
          ["premiumStartDate", user.stripeSubscriptionPeriodStart]
        ]
          // eslint-disable-next-line no-unused-vars
          .filter(([_, value]) => value)
          .map(([key, value]) => [key, this.formatDate(value)])
      )
    }

    if (this.requiresIdentity) {
      this.requiresIdentity = false
      analytics.identify(user.email, props)
    }
  }

  formatDate(value, format = "yyyy-MM-dd") {
    return Utils.formatDate(value, format)
  }

  setGlobalProperties(properties = {}) {
    this.globalProperties = { ...this.globalProperties, ...properties }
  }

  trackEvent(event, properties = {}) {
    analytics.track(event, { ...this.getGlobalProperties(), ...properties })
  }

  trackButtonClickEvent(properties = {}) {
    const eventName = properties.name || AnalyticsEvents.BUTTON_CLICK
    this.trackEvent(eventName, properties)
  }
}
