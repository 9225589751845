import axios from "axios"
import api from "@/store/api"
import router from "@/router"
import { Utils, whenExtensionMounted } from "@/utils"
import { AnalyticsEvents } from "@/plugins/analytics/events"

const ERROR_MSGS = {
  wrongCredentials: "Your email or password is incorrect.",
  accountNotActive:
    "Your account is not active. Please check the email link to activate it.",
  accountNotFound: "Could not find an account with this email.",
  forbiddenGoogleAuthLogin:
    "Regular sign in is not available for Google linked accounts.",
  forbiddenGooglePassReset: "Password reset is not available for Google linked accounts.",
  emailAlreadyUsed: "You already have an account with this email.",
  generic: "Something went wrong. Our team has been notified and is working on it."
}

export const state = {
  user: {},
  responseMsg: "",
  errorMsg: "",
  loading: null
}

export const mutations = {
  SET_AUTH_STATE(state, { key, value }) {
    state[key] = value
  },
  SET_USER(state, data) {
    state.user = data
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_RESP_MSG(state, msg) {
    state.responseMsg = msg
  },
  SET_ERROR_MSG(state, msg) {
    state.errorMsg = msg
  },
  RESET_AUTH_MSGS(state) {
    state.responseMsg = ""
    state.errorMsg = ""
  }
}

export const actions = {
  async signIn({ state, commit, dispatch, rootState }, payload) {
    try {
      commit("SET_LOADING", "signIn")
      commit("RESET_AUTH_MSGS")

      const { data } = await axios.post(api.login, payload)
      dispatch("track_auth", {
        user_id: rootState.installationId,
        action: "signIn email pass",
        context: process.env.VUE_APP_URL,
        email: payload.email
      })
      commit("SET_USER", Utils.normalizeUserData(data))

      this.$analytics.identifyUser(Utils.normalizeUserDataForAnalytics(state.user))
      this.$analytics.trackEvent(AnalyticsEvents.SIGN_IN, {
        provider: "credentials",
        email: payload.email
      })
      await router.push({
        name: "home"
      })
    } catch (err) {
      if (err.response?.status === 401) {
        commit("SET_ERROR_MSG", ERROR_MSGS.wrongCredentials)
        dispatch("track_auth", {
          user_id: rootState.installationId,
          action: "signIn email pass",
          context: process.env.VUE_APP_URL,
          email: payload.email,
          notes: "error | wrong credentials"
        })
      } else if (err.response?.status === 402) {
        commit("SET_ERROR_MSG", ERROR_MSGS.accountNotActive)
        dispatch("track_auth", {
          user_id: rootState.installationId,
          action: "signIn email pass",
          context: process.env.VUE_APP_URL,
          email: payload.email,
          notes: "error | account not active"
        })
      } else if (err.response?.status === 406) {
        commit("SET_ERROR_MSG", ERROR_MSGS.forbiddenGoogleAuthLogin)
      } else if (err.response?.status) {
        commit("SET_ERROR_MSG", ERROR_MSGS.generic)
      }
      Utils.log(err, { scope: "Store | signIn" })
    } finally {
      commit("SET_LOADING", null)
    }
  },
  async get_user({ commit }, { next, handleRedirect, query }) {
    try {
      commit("SET_ERROR_MSG", ERROR_MSGS.accountNotActive)
      const { data } = await axios.get(api.login)
      commit("SET_USER", Utils.normalizeUserData(data))

      this.$analytics.identifyUser(Utils.normalizeUserDataForAnalytics(state.user))

      // We know for sure that we have the 'isExtensionInstalled' param when coming from google auth redirect
      const isGoogleAuth = !!query.isExtensionInstalled
      isGoogleAuth &&
        this.$analytics.trackEvent(
          query.newUserEmail ? AnalyticsEvents.SIGN_UP : AnalyticsEvents.SIGN_IN,
          {
            provider: "google",
            email: data.name
          }
        )
      handleRedirect(query)
    } catch (err) {
      Utils.log(err, { scope: "Store | get_user" })
      next("/signIn")
    }
  },
  async signUp({ state, commit, dispatch, rootState }, payload) {
    try {
      commit("SET_LOADING", "signUp")
      commit("RESET_AUTH_MSGS")
      await axios.post(api.signup, payload, {
        params: {
          isExtensionInstalled: (await whenExtensionMounted()) ? "yes" : "no",
          utmSource: state.utmSource
        }
      })
      dispatch("track_auth", {
        user_id: rootState.installationId,
        action: "signUp email pass",
        context: process.env.VUE_APP_URL,
        email: payload.email,
        notes: "email validation link sent"
      })
      this.$analytics.identifyUser({ email: payload.email })
      this.$analytics.trackEvent(AnalyticsEvents.SIGN_UP, {
        provider: "credentials",
        email: payload.email
      })
      await router.push({
        query: {
          hideGoogleAuth: "yes"
        }
      })
      commit(
        "SET_RESP_MSG",
        "Before getting started, you need to validate your account. Please check your email and click the link. If you don't see our email in your inbox, please check your spam folder."
      )
    } catch (err) {
      if (err.response?.status === 409) {
        commit("SET_ERROR_MSG", ERROR_MSGS.emailAlreadyUsed)
        dispatch("track_auth", {
          user_id: rootState.installationId,
          action: "signUp email pass | error",
          context: process.env.VUE_APP_URL,
          email: payload.email,
          notes: "error | email already used"
        })
      } else if (err.response?.status === 405) {
        commit("SET_ERROR_MSG", ERROR_MSGS.accountNotActive)
        dispatch("track_auth", {
          user_id: rootState.installationId,
          action: "signUp email pass | error",
          context: process.env.VUE_APP_URL,
          email: payload.email,
          notes: "error | account not active"
        })
      } else if (err.response?.status) {
        commit("SET_ERROR_MSG", ERROR_MSGS.generic)
      }
      Utils.log(err, { scope: "Store | signUp" })
    } finally {
      commit("SET_LOADING", null)
    }
  },
  async verify_signup({ commit, dispatch, rootState }, { id, email, referralCode }) {
    try {
      await axios.post(
        `${api.verifySignup}/${id}/${email}${referralCode ? "/" + referralCode : ""}`
      )

      dispatch("track_auth", {
        user_id: rootState.installationId,
        action: "link validated",
        context: process.env.VUE_APP_URL,
        email
      })

      this.$analytics.identifyUser({ email })
      this.$analytics.trackEvent(AnalyticsEvents.EMAIL_VERIFIED, {
        email,
        referralCode
      })
      commit(
        "SET_RESP_MSG",
        "Account verified, you will be redirected to login page in few moments."
      )

      const referrer = JSON.parse(localStorage.getItem("PitchMeAI:referrer"))
      const referrerUrl = referrer?.url

      setTimeout(() => {
        commit("SET_RESP_MSG", "")
        commit("RESET_AUTH_MSGS")

        router.push({
          name: "signIn",
          query: {
            referrer: referrerUrl,
            hideGoogleAuth: "yes"
          }
        })
      }, 3000)
    } catch (err) {
      commit(
        "SET_RESP_MSG",
        "Your account could not be verified. Please send us an email at support@pitchmeai.com or try to signup again."
      )
      Utils.log(err, { scope: "Store | verify_signup" })
    }
  },
  async getPasswordResetLink({ commit }, { email }) {
    try {
      commit("SET_LOADING", "getPasswordResetLink")
      await axios.post(api.getPasswordResetLink, { email })
      commit(
        "SET_RESP_MSG",
        "A password reset link has been sent to your email. Please check your inbox or spam folder."
      )
    } catch (err) {
      if (err.response?.status === 404) {
        commit("SET_ERROR_MSG", ERROR_MSGS.accountNotFound)
      } else if (err.response?.status === 406) {
        commit("SET_ERROR_MSG", ERROR_MSGS.forbiddenGooglePassReset)
      } else if (err.response?.status) {
        commit("SET_ERROR_MSG", ERROR_MSGS.generic)
      }
      Utils.log(err, { scope: "Store | getPasswordResetLink" })
    } finally {
      commit("SET_LOADING", null)
    }
  },
  async resetPassword({ commit }, { id, email, password }) {
    try {
      commit("SET_LOADING", "resetPassword")
      await axios.post(`${api.resetPassword}/${id}/${email}`, { password })
      commit(
        "SET_RESP_MSG",
        "Your password has been reset successfully. You will be redirected to login page in few moments."
      )

      const referrer = JSON.parse(localStorage.getItem("PitchMeAI:referrer"))
      const referrerUrl = referrer?.url

      setTimeout(() => {
        commit("RESET_AUTH_MSGS")

        router.push({
          name: "signIn",
          query: {
            referrer: referrerUrl,
            hideGoogleAuth: "yes"
          }
        })
      }, 3000)
    } catch (err) {
      if (err.response?.status) {
        commit("SET_ERROR_MSG", ERROR_MSGS.generic)
      }
      Utils.log(err, { scope: "Store | resetPassword" })
    } finally {
      commit("SET_LOADING", null)
    }
  },
  async track_auth(ctx, payload) {
    try {
      await axios.post(`${api.analytics}/auth`, payload)
    } catch (err) {
      Utils.log(err, { scope: "Store | track_auth" })
    }
  }
}
