import { AnalyticsService, injectionKey } from "./analytics.service.js"

export const AnalyticsPlugin = {
  install(app, { store, extraProperties } = {}) {
    const analyticsService = new AnalyticsService()
    extraProperties && analyticsService.setGlobalProperties(extraProperties)

    // Attach to vuex and provide in app
    store && (store[injectionKey] = analyticsService)
    app.provide(injectionKey, analyticsService)
    Object.defineProperty(app.config.globalProperties, injectionKey, {
      get: () => analyticsService
    })
  }
}
