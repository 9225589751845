import { createRouter, createWebHistory } from "vue-router"
import store from "@/store"
import { ALLOWED_DOMAINS, Utils, whenExtensionMounted } from "@/utils"

const EmptyComponent = { render: () => {} }

const routes = [
  {
    path: "/",
    children: [
      {
        path: "",
        component: () => import("../layouts/LoginLayout.vue"),
        children: [
          {
            path: "signIn",
            name: "signIn",
            component: () => import("../views/AuthView.vue"),
            meta: {
              title: "Sign in"
            }
          },
          {
            path: "signUp",
            name: "signUp",
            component: () => import("../views/AuthView.vue"),
            meta: {
              title: "Sign up"
            }
          },
          {
            path: "get-pass-reset-link",
            name: "getPasswordResetLink",
            component: () => import("../views/AuthView.vue"),
            meta: {
              title: "Get password reset link",
              submitBtnText: "Get link"
            }
          },
          {
            path: "reset-pass",
            name: "resetPassword",
            component: () => import("../views/AuthView.vue"),
            meta: {
              title: "Reset password",
              submitBtnText: "Reset"
            }
          }
        ]
      },
      {
        path: "/home",
        name: "home",
        meta: { auth: true },
        component: () => import("../views/HomeView.vue")
      },
      {
        path: "/verify-account-registration/:id/:email/:referralCode?",
        name: "verifyRegistration",
        component: () => import("../views/VerifyRegistrationView.vue")
      },
      {
        path: "/analytics",
        component: EmptyComponent,
        children: [
          {
            path: "events",
            component: EmptyComponent,
            children: [
              {
                path: "install",
                component: EmptyComponent,
                meta: {
                  title: "PitchMeAI Install Event"
                }
              },
              {
                path: "upgrade",
                component: EmptyComponent,
                meta: {
                  title: "PitchMeAI Upgrade Event"
                }
              }
            ]
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async ({ meta, query, path }, from, next) => {
  const title = (meta.title ? meta.title + " | " : "") + "PitchMeAI - Get More Job Offers"
  document.title = title

  if (path.includes("analytics")) {
    document.title = meta.title
  } else {
    const title =
      (meta.title ? meta.title + " | " : "") + "PitchMeAI - Get More Job Offers"
    document.title = title
  }

  store.commit("RESET_AUTH_MSGS")

  if (meta?.auth || path === "/") {
    const userEmail = store.state.auth.user?.name

    // google login / direct route enter / from other app route and not logged in
    if (!userEmail) {
      await store.dispatch("get_user", { next, handleRedirect, query })
    }

    // from other app route and logged in (regular login or else)
    if (userEmail) {
      handleRedirect(query)
    }

    return
  }

  next()
})

router.afterEach((to) => {
  if (to.path.includes("analytics/events/upgrade") && to.query.redirectUrl) {
    const url = new URL(to.query.redirectUrl)
    if (!ALLOWED_DOMAINS.includes(url.hostname)) return

    window.location.href = to.query.redirectUrl
  }
})

async function handleRedirect(query) {
  try {
    const referrerUrl = JSON.parse(localStorage.getItem("PitchMeAI:referrer"))?.url
    const isExtensionInstalled =
      query.isExtensionInstalled === "yes" || (await whenExtensionMounted())

    if (!isExtensionInstalled) {
      window.location.href = process.env.VUE_APP_CHROMESTORE_URL
      return
    }

    if (referrerUrl) {
      localStorage.removeItem("PitchMeAI:referrer")
      let redirectUrl = decodeURIComponent(referrerUrl)
      const separator = redirectUrl.includes("?") ? "&" : "?"
      redirectUrl += `${separator}pitchmeai-sign-in=yes`
      window.location.href = redirectUrl
      return
    }

    window.location.href =
      "https://www.linkedin.com/jobs/search/?pitchmeai-show-drawer=yes&pitchmeai-sign-in=yes"
  } catch (error) {
    Utils.log(error, { scope: "Router | handleRedirect" })
  }
}

export default router
